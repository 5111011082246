import React, {
  useState, useMemo, useContext, useEffect,
} from 'react';
import { Collapse, Spin } from 'antd';
import { Link } from 'gatsby';
import htmr from 'htmr';
import { StaticImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';
import { API, DataStore } from 'aws-amplify';
import { RouteComponentProps, useLocation } from '@reach/router';
import * as styles from '../../../styles/admin/CourseDetails.module.scss';
import { Course, TaskType } from '../../../models';
import AdminContext from '../../../context/Admin/AdminContext';
import { updateEnterpriseProfile } from '../../../graphql/mutations';
import { isEligibleToHideCourse, toaster } from '../../../services/utils';
import { GrowthToolTabs } from '../../../types/commons';
import AssignCoursePopup from './Popups/AssignCoursePopup';
import AssignCourseStatusPopup from './Popups/AssignCourseStatusPopup';
import { getCourseById } from '../../GraphQL/Services';
import { CourseAccessType, CourseStatus } from '../../../API';
import UploadCoursePopup from '../GrowthTools/UploadCoursePopup';

const { Panel } = Collapse;

interface Props extends RouteComponentProps {
  courseId?: string,
}

type UploadCoursePopupType = {
  isVisible: boolean,
  courseData: Course | any
}

const CourseDetailsView: React.FC<Props> = ({ courseId = '' }) => {
  const { profileInfo, companyInfo, setCompanyInfo } = useContext(AdminContext);
  const [disabledCta, setDisabledCta] = useState(false);
  const [course, setCourse] = useState<Course | null>({} as Course);
  const [isAssignCourseModalVisible, setIsAssignCourseModalVisible] = useState(false);
  const [isAssignCourseStatusModalVisible, setIsAssignCourseStatusModalVisible] = useState(false);
  const [uploadCoursePopup, setUploadCoursePopup] = useState<UploadCoursePopupType>({
    isVisible: false,
    courseData: null,
  });
  const [usersList, setUsersList] = useState<string[]>(['']);
  const [loader, setLoader] = useState(true);

  const isPrivateCourse = course?.accessType === CourseAccessType.PRIVATE;

  const location: any = useLocation();

  const courseType = (index: number) => {
    if (index === 0) {
      return styles.beginner;
    } if (index === 1) {
      return styles.intermediate;
    } if (index === 2) {
      return styles.advanced;
    } return styles.expert;
  };

  const totalClasses = useMemo(() => {
    if (course?.modules?.length) {
      let count = 0;
      course?.modules.forEach((module) => {
        if (module?.tasks?.length) {
          count += module.tasks.filter((task) => task.taskType === TaskType.VIDEO)?.length;
        }
      });
      return count;
    }
    return 0;
  }, [course]);

  const updateCourseVisibility = async () => {
    setDisabledCta(true);
    try {
      if (course?.id) {
        const hiddenCourses = companyInfo?.hiddenCourses || [];
        const hiddenCourseIdsSet = new Set(hiddenCourses);
        if (hiddenCourseIdsSet.has(course?.id)) {
          hiddenCourseIdsSet.delete(course.id);
        } else {
          hiddenCourseIdsSet.add(course.id);
        }
        const payload = {
          id: companyInfo?.id,
          // eslint-disable-next-line no-underscore-dangle
          _version: companyInfo?._version,
          hiddenCourses: [...hiddenCourseIdsSet],
        };
        const response: any = await API.graphql({
          query: updateEnterpriseProfile,
          variables: {
            input: payload,
          },
        });
        if (response?.data?.updateEnterpriseProfile) {
          setCompanyInfo(response.data.updateEnterpriseProfile);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setDisabledCta(false);
  };

  const isCourseHiddenFromLearners = useMemo(() => {
    const hiddenCourses = companyInfo?.hiddenCourses || [];
    return hiddenCourses.includes(courseId);
  }, [companyInfo, course?.id]);

  const isEligible = isEligibleToHideCourse(profileInfo?.roles?.[0]);

  const fetchCourseDetails = async () => {
    try {
      if (courseId) {
        const courseData = await DataStore.query(Course, courseId);
        if (courseData) {
          setCourse(courseData);
        } else {
          const privateCourse = await getCourseById(courseId);
          if (privateCourse) {
            setCourse(privateCourse);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const openAssignCoursePopupHandler = () => {
    setIsAssignCourseModalVisible(true);
  };

  const closeAssignCourseStatusPopupHandler = () => {
    setIsAssignCourseStatusModalVisible(false);
  };

  const tabNavigationHandler = () => {
    if (location?.state?.sourceTab === GrowthToolTabs.HIDE_COURSES) {
      return '/admin/growth-tools/hide-courses/';
    }
    if (location?.state?.sourceTab === GrowthToolTabs.UPLOAD_COURSE) {
      return '/admin/growth-tools/upload-course/';
    }

    return '/admin/growth-tools/assign-courses/';
  };

  const getDOMIDForNavigateBackCTA = () => {
    if (location?.state?.sourceTab === GrowthToolTabs.HIDE_COURSES) {
      return 'admin-growth-tools-hide-courses-navigate-back';
    }
    if (location?.state?.sourceTab === GrowthToolTabs.UPLOAD_COURSE) {
      // TODO: Add id in google sheet
      return 'admin-growth-tools-upload-course-navigate-back';
    }
    return 'admin-growth-tools-assign-courses-navigate-back';
  };

  const onSuccess = async (message:string) => {
    const privateCourse = await getCourseById(courseId);
    setUploadCoursePopup((prev) => ({
      ...prev,
      isVisible: false,
    }));
    if (privateCourse) {
      setCourse(privateCourse);
    }
    toaster(message);
  };

  const openUploadCoursePopup = () => {
    setUploadCoursePopup({
      isVisible: true,
      courseData: course,
    });
  };

  useEffect(() => {
    if (courseId && companyInfo?.id) {
      fetchCourseDetails();
    }
  }, [courseId, companyInfo]);

  if (loader) {
    return <Spin size="large" className={styles.courseDetailsPageLoader} />;
  }

  return (
    <div>
      {course?.id && (
        <div className={`${styles.courseDetails} library-shaw-icon-new`}>
          <div className={styles.headingSection}>
            <div className={styles.courseSection}>
              <Link id={getDOMIDForNavigateBackCTA()} to={tabNavigationHandler()}>
                <i className="icon-new-Left-Arrow-6" />
              </Link>
              <img
                width={200}
                height={120}
                src={course?.courseHeroBannerImage?.href}
                alt={course?.courseHeroBannerImage?.title}
              />
              <div className={styles.infoSection}>
                <div className={`${styles.part} ${isPrivateCourse ? styles.update : ''}`}>
                  {!isPrivateCourse && (
                  <p className={styles.info}>
                    Diploma in
                  </p>
                  )}
                  <p className={styles.title}>
                    {course?.courseName}
                  </p>
                </div>
                {isCourseHiddenFromLearners
                  && (
                  <p className={styles.hiddenCourse}>
                    Course is hidden from all your Learners!
                  </p>
                  )}
              </div>
            </div>
            { location?.state?.sourceTab === GrowthToolTabs.HIDE_COURSES
              && ((isEligible && companyInfo?.id && course?.id) ? (
                <div className={styles.ctaWrapper}>
                  <button
                    id={isCourseHiddenFromLearners ? 'admin-growth-tools-hide-courses-show-course-to-learners' : 'admin-growth-tools-hide-courses-hide-course-from-learners'}
                    type="button"
                    className={styles.cta}
                    onClick={updateCourseVisibility}
                    disabled={disabledCta}
                  >
                    <p>{isCourseHiddenFromLearners ? 'Show course to Learners' : 'Hide course from Learners'}</p>
                  </button>
                  {disabledCta && <Spin />}
                </div>
              ) : null)}
            {
              (location?.state?.sourceTab) === GrowthToolTabs.ASSIGN_COURSES
              && (
                <div className={styles.ctaWrapper}>
                  <button
                    id="admin-growth-tools-assign-courses-assign-course-to-learners"
                    type="button"
                    className={styles.assignCourseCta}
                    onClick={openAssignCoursePopupHandler}
                    disabled={disabledCta}
                  >
                    <img className={styles.shareIcon} src="/images/admin/share-icon.png" alt="Share icon" />
                    Assign course to Learners
                  </button>
                </div>
              )
            }
            {
              (location?.state?.sourceTab) === GrowthToolTabs.UPLOAD_COURSE
                && course?.status === CourseStatus.UPLOAD_FAILED
                && (
                <div className={styles.ctaWrapper}>
                  <button
                    id="admin-growth-tools-upload-course-retry-upload"
                    type="button"
                    className={styles.cta}
                    onClick={openUploadCoursePopup}
                  >
                    <p>Retry Upload</p>
                  </button>
                </div>
                )
            }
          </div>
          {!isPrivateCourse && <hr />}
          <div className={styles.courseInfoSection}>
            {!isPrivateCourse && (
            <div className={styles.accreditationCourseDetails}>
              <div className={styles.week}>
                <i className="icon-new-Play-31" />
                <div>
                  <p>
                    {totalClasses}
                    {' '}
                    classes
                  </p>
                  <p>On Demand Learning</p>
                </div>
              </div>
              <div className={styles.skillLevel}>
                <i className="icon-new-Diamond" />
                <div>
                  <p>Skill Level</p>
                  <p>Beginner & Advanced</p>
                </div>
              </div>
              <div className={styles.certifiedBy}>
                <i className="icon-new-Play-31" />
                <div>
                  <p>Certified by</p>
                  <p>CPD</p>
                </div>
              </div>
              <div className={styles.language}>
                <i className="icon-new-Diamond" />
                <div>
                  <p>Language</p>
                  <p>English</p>
                </div>
              </div>
              <div className={styles.language}>
                <i className="icon-new-Diamond" />
                <div>
                  <p>Sharable</p>
                  <p>Diploma Certificate</p>
                </div>
              </div>
            </div>
            )}
            {!isPrivateCourse && <hr />}
            <div className={styles.bodySection}>
              <div className={styles.wrapper}>
                <div className={styles.courseInfo}>
                  <h4 className={styles.sectionHeading}>
                    About the Course
                  </h4>
                  <div className={`${styles.courseDescription} ${isPrivateCourse ? styles.update : ''}`}>
                    {course?.courseDescription && htmr(course.courseDescription)}
                  </div>
                </div>
                {!isPrivateCourse
                && (
                <div className={styles.accreditationSection}>
                  <p className={styles.accreditationSectionTitle}>Certified by</p>
                  <div className={styles.imageWrapper}>
                    <div>
                      <StaticImage
                        src="../../../../static/images/shared/CPD.png"
                        alt="cpd"
                        height={80}
                      />
                    </div>
                  </div>
                </div>
                )}
              </div>
              {!isPrivateCourse
              && (
              <div className={styles.courseJourneyWrapper}>
                <p className={styles.title}>Course Journey</p>
                <div className={styles.journey}>
                  <div className={styles.moduleWrapper}>
                    <p className={styles.startJourney}>Starting today</p>
                    <div className={styles.divider} />
                    {course?.modules?.length > 0
                      ? (
                        course?.modules.map((_, moduleIndex) => (
                          <React.Fragment key={uuidv4()}>
                            <div className={styles.moduleDiv}>
                              <p className={styles.moduleName}>
                                Module
                                {' '}
                                0
                                {moduleIndex + 1}
                              </p>
                            </div>
                            <div className={styles.divider} />
                          </React.Fragment>
                        ))
                      )
                      : null}
                    <p className={styles.endJourney}>Finish and Get Diploma</p>
                  </div>
                </div>
              </div>
              )}
              {!isPrivateCourse && (
              <div className={styles.moduleInfoSection}>
                <Collapse
                  expandIconPosition="right"
                  expandIcon={(expandProps) => {
                    if (expandProps?.isActive) {
                      return (
                        <span className={styles.collapse}>-</span>
                      );
                    }
                    return (
                      <span className={styles.expand}>+</span>
                    );
                  }}
                >
                  {course?.modules?.map((module, index) => (
                    <Panel
                      header={(
                        <div>
                          <p className={styles.moduleNo}>
                            <span>{`Module ${index + 1}`}</span>
                            <span
                              className={courseType(index)}
                            />
                          </p>
                          <p className={styles.moduleName}>{module.moduleName}</p>
                        </div>
                      )}
                      key={module?.id}
                    >
                      <Collapse
                        expandIconPosition="right"
                        className={styles.lessonAccordion}
                        expandIcon={(expandProps) => {
                          if (expandProps?.isActive) {
                            return (
                              <span className={`library-shaw-icon-new ${styles.lessonCollapse}`}>
                                <i className="icon-new-Right-arrow-1" />
                              </span>
                            );
                          }
                          return (
                            <span className={`library-shaw-icon-new ${styles.lessonExpand}`}>
                              <i className="icon-new-Right-arrow-1" />
                            </span>
                          );
                        }}
                      >
                        {module?.tasks.filter((task) => task.videoTask).map((lesson, i) => (
                          lesson?.videoTask?.lessonName && (
                          <Panel
                            header={`${i + 1}.${lesson?.videoTask?.lessonName}`}
                            key={lesson?.id}
                            className={styles.lesson}
                          >
                            {lesson?.videoTask?.lessonDescription
                              ? htmr(lesson?.videoTask?.lessonDescription) : null}
                          </Panel>
                          )
                        ))}
                      </Collapse>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              )}
            </div>
          </div>
        </div>
      )}
      <AssignCoursePopup
        course={course}
        isModalVisible={isAssignCourseModalVisible}
        setIsModalVisible={setIsAssignCourseModalVisible}
        setIsAssignCourseStatusModalVisible={setIsAssignCourseStatusModalVisible}
        setUsersList={setUsersList}
      />
      <AssignCourseStatusPopup
        isModalVisible={isAssignCourseStatusModalVisible}
        onCancel={closeAssignCourseStatusPopupHandler}
        usersList={usersList}
        course={course}
      />
      <UploadCoursePopup
        isVisible={uploadCoursePopup.isVisible}
        onSuccess={onSuccess}
        courseData={uploadCoursePopup.courseData}
        onCancel={() => setUploadCoursePopup((prev) => ({ ...prev, isVisible: false }))}
        domIDs={{
          cta: 'admin-growth-tools-upload-course-retry-upload-upload-course-popup-upload',
        }}
      />
    </div>
  );
};

export default CourseDetailsView;
