import {
  Form, Modal, Select,
} from 'antd';
import React, {
  useContext, useEffect, useState,
} from 'react';
import * as commonPopupStyles from '../../../../styles/admin/PopupCommon.module.scss';
import * as adduserStyles from '../../../../styles/admin/AddUser.module.scss';
import { Course, Role } from '../../../../models';
import AdminContext from '../../../../context/Admin/AdminContext';
import { getCourseEnrolledUsers } from '../../../GraphQL/Services';
import { toaster } from '../../../../services/utils';
import { EnterpriseUser } from '../../../../types/enterpriseUser';

type Props = {
  course: Course | null;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAssignCourseStatusModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setUsersList: React.Dispatch<React.SetStateAction<string[]>>
}

type SelectUserInfo = {
  label: string,
  value: string
}

const AssignCoursePopup: React.FC<Props> = ({
  course,
  isModalVisible,
  setIsModalVisible,
  setIsAssignCourseStatusModalVisible,
  setUsersList,
}) => {
  const [nonEnrolledUsers, setNonEnrolledUsers] = useState<SelectUserInfo[]>([]);

  const { profileInfo, enterpriseUsersList } = useContext(AdminContext);
  const [form] = Form.useForm();

  const onCancel = () => {
    form.setFieldsValue({
      selectedUsers: [],
    });
    setIsModalVisible(false);
  };

  const submitForm = () => {
    const selectedUsers = form.getFieldValue('selectedUsers');
    if (selectedUsers?.length > 0) {
      if (selectedUsers?.length > 20) {
        toaster("Selected users' count cannot exceed 20 ", 'error');
        return;
      }

      setUsersList(selectedUsers);
      setIsModalVisible(false);
      setIsAssignCourseStatusModalVisible(true);
      form.setFieldsValue({
        selectedUsers: [],
      });
    }
  };

  const fetchUsersData = async () => {
    const enterpriseId = profileInfo?.enterpriseID;
    const courseId = course?.id;

    const listOfEnrolledUsers = await getCourseEnrolledUsers(enterpriseId, courseId);

    const listOfUserIdsEnrolled = listOfEnrolledUsers?.map((user: any) => user?.userID);

    const allUsersList = enterpriseUsersList ? [...(enterpriseUsersList).values()] : [];

    const users = allUsersList.filter((acceptedUser) => {
      if (acceptedUser?.roles?.includes(Role.OWNER)) {
        return false;
      }
      if (listOfUserIdsEnrolled.includes(acceptedUser?.id)) {
        return false;
      }

      return true;
    });

    const userListForSelect = users?.map((user: EnterpriseUser) => ({
      label: user?.email,
      value: user?.id,
    }));

    setNonEnrolledUsers(userListForSelect);
  };

  useEffect(() => {
    if (course?.id
      && profileInfo?.id
      && isModalVisible
      && enterpriseUsersList) {
      fetchUsersData();
    }
  }, [course, profileInfo, isModalVisible, enterpriseUsersList]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => {
        form.setFieldsValue({
          selectedUsers: [],
        });
        onCancel();
      }}
      width="85%"
      className="wrapper"
      footer={null}
    >
      <Form
        form={form}
        onFinish={submitForm}
      >
        <div className={`${adduserStyles.assignCourseWrapper} ${adduserStyles.addUserWrapper}`}>
          <div>
            <div className={commonPopupStyles.titleWrapper}>
              <p>Assign a Course</p>
            </div>
            <div className={`${adduserStyles.addEmail} add-email`}>
              <p className={adduserStyles.labelWrapper}>
                <p className={adduserStyles.titleLabel}>Course name</p>
              </p>
              <div className={adduserStyles.courseTitleWrapper}>
                <p>
                  {course?.courseName}
                </p>
              </div>
              <p className={adduserStyles.labelWrapper}>
                <p className={adduserStyles.usersLabel}>Add Users/Managers</p>
              </p>
              <Form.Item
                name="selectedUsers"
              >
                <Select
                  id="selectMenu"
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please enter email id"
                  options={nonEnrolledUsers}
                  optionFilterProp="label"
                  filterOption={
                  (input, option) => (option?.label as any)
                    .toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                  listHeight={180}
                  optionLabelProp="label"
                  filterSort={
                  (optionA, optionB) => (optionA?.label as any).toLowerCase()
                    .localeCompare((optionB?.label as any)?.toLowerCase())
                }
                />
              </Form.Item>
            </div>
          </div>
          <div className={adduserStyles.assignCtaWrapper}>
            <button
              id="admin-growth-tools-assign-courses-assign-course-popup-assign"
              type="submit"
              className={`${commonPopupStyles.confirmButton} ${adduserStyles.assignCourseCta}`}
            >
              Assign
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AssignCoursePopup;
