// import { RouteComponentProps } from '@reach/router';
import { Router } from '@reach/router';
import React from 'react';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import AuthorizationWrapper from '../AuthorizationWrapper';
import HeaderComponent from '../Header';
import SidebarComponent from '../Sidebar';
import CourseDetailsView from './CourseDetailsView';
import * as styles from '../../../styles/admin/Account.module.scss';

const CourseDetailsWrapper:React.FC = () => (
  <div className={styles.accountPageWrapper}>
    <AdminProvider>
      <AuthorizationWrapper>
        <HeaderComponent />
        <div style={{ display: 'flex' }}>
          <SidebarComponent />
          <div className={styles.accountWrapper}>
            <Router basepath="/admin/course-details">
              <CourseDetailsView path=":courseId" />
              <CourseDetailsView default />
            </Router>
          </div>
        </div>
      </AuthorizationWrapper>
    </AdminProvider>
  </div>
);

export default CourseDetailsWrapper;
